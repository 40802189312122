.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-bottom: 10rem;
    position: relative;
}

.background {
    background-color: var(--main-color);
    height: 65%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
}

@media (max-width: 450px) {
    .background {
        height: 80%;
    }
}

.reason_wrapper {
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.reason_container {
    max-width: 100rem;
    width: 95%;
}

.reason {
    text-align: center;
    color: #fff;
    font-size: 5rem;
}

.description {
    padding: 2rem 4rem;
    border-radius: 0.5rem;
    color: #fff;
    font-weight: bold;
}

.illustration {
    background-color: #fff;
    padding: 4rem;
    border-radius: 1rem;
    box-shadow: var(--shadow-default);
    max-width: 70rem;
    width: 95%;
}
